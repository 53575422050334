import React from "react"
import emailjs from "emailjs-com"
import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
import Image from "reusecore/Image"
import Button from "reusecore/Button"
import Input from "reusecore/Form/Input"
import { SectionTitle } from "reusecore/SectionTitle"

import SubscribeWrapper from "./subscribe.style"

const Subscribe = () => {
  function sendEmail(e) {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_pxw5rvc",
        "template_q3wj7jt",
        e.target,
        "user_4g2MJWFtaKeIjR3SsH4E6"
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
    e.target.reset()
  }

  return (
    <SubscribeWrapper id="contact">
      <Box className="subscribe-box-wrapper">
        <Container>
          <Text className="Omnize-Heading"> Schedule a consultation </Text>{" "}
          <form className="contact-form" onSubmit={sendEmail}>
            <Box className="subscribe-box">
              <Row className="omnize-mb-4">
                <Col className="lg-6  xs-12">
                  <label className="label-name">
                    FULL NAME<span className="omnize-star">*</span>
                  </label>
                  <Input
                    type="text"
                    placeholder="John Doe"
                    name="from_name"
                    required
                  />
                </Col>{" "}
                <Col className="lg-6  xs-12">
                  <label className="label-name">
                    BUSINESS NAME<span className="omnize-star">*</span>
                  </label>
                  <Input
                    type="text"
                    placeholder="Lorem Ipsum"
                    name="business_name"
                  />
                </Col>{" "}
              </Row>{" "}
              <Row className="omnize-mb-4">
                <Col className="lg-6  xs-12">
                  <label className="label-name">
                    JOB POSITION<span className="omnize-star">*</span>
                  </label>

                  <Input
                    type="text"
                    placeholder="Lorem Ipsum"
                    name="job_position"
                  />
                </Col>{" "}
                <Col className="lg-6  xs-12">
                  <label className="label-name">
                    EMAIL ADDRESS<span className="omnize-star">*</span>
                  </label>

                  <Input type="text" placeholder="mail@xyz.com" name="email" />
                </Col>{" "}
              </Row>{" "}
              <Row className="omnize-mb-4">
                <Col className="lg-6  xs-12">
                  <label className="label-name">PHONE NUMBER</label>

                  <Input
                    type="text"
                    placeholder="012345678"
                    name="phone_number"
                  />
                </Col>{" "}
                <Col className="lg-6  xs-12">
                  <label className="label-name">MESSAGE</label>
                  <Input type="text" placeholder="Notes" name="notes" />
                </Col>{" "}
              </Row>{" "}
              <div className="form-box">
                <input type="submit" value="Submit" className="button" />
              </div>
            </Box>{" "}
          </form>
        </Container>{" "}
      </Box>{" "}
    </SubscribeWrapper>
  )
}

export default Subscribe
