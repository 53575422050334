const theme = {
    black: "#333333",
    white: "#ffffff",
    primaryColor: "#004085",
    primaryHoverColor: "#cce5ff",
    secondaryColor: "#383d41",
    headingColor: "#FFFFFF",
    bodyColor: "#BEABDF",
    linkColor: "#1F2AD5",
    NavBG: "transparent",
    NavBGSolid: "#290E59",
    menuHoverColor: "#1F2AD5",
}
export default theme